import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter, generatePath } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import moment from 'moment';

import { pick } from 'ramda';

import { AsyncResourceHandler } from 'src/modules/app';
import { ErrorProps } from 'src/components';
import { ContactDetailsValues } from 'src/components/ContactDetailsModal';
import { notificationService } from 'src/modules/offer/service';
import { paths } from 'src/constants/variables';
import { MapState, MapDispatch } from 'src/store';

import { calculateTotalPriceByCartItem } from 'src/modules/cart/helper';
import { JsonObject, VariantItem } from 'src/helpers/types';
import { editCartItem } from 'src/modules/cart/store/cartActions';
import { cartService } from 'src/modules/cart/store/cartService';
import { clearCartAfterSubmit } from 'src/modules/cart/store/cartActions';
import { fetchCartItem } from 'src/modules/product/store/productActions';
import { OfferLayout } from '../../components/OfferLayout';
import { CustomerDetailsValues } from 'src/components/CustomerDetailsModal';
import { ClearFormButton } from '../../components/ClearFormButton';
import { prepareCartItemData, prepareVariantItem } from '../../helpers/offerData';
import { getOfferFormDefaultValues } from '../../helpers/offerForm';
import { createNewOfferItemDetailsFromCartResponse } from '../../helpers/createOfferItemDetailsFromCartResponse';
import {
    resetOfferForm,
    persistOfferValues,
    persistOfferWarnings,
    updateContactDetails,
    updateCustomerDetails,
    updateOfferItem,
} from '../../store/actions/offerActions';
import {
    getOverviewAvailableColors,
    getOverviewAvailableWeights,
    getOverviewRotationRates,
    getOfferPersistState,
    getOfferState,
} from '../../store/selectors/offerSelectors';
import { fetchOfferDetails, submitOfferForm } from '../../service/offerServices';
import { OfferFormItem, OfferFormValues } from '../../components/types';
import { createOfferPdf } from '../../../offerPdf/services/createOfferPdf';
import {
    clearOfferForm,
    loadOfferOverview,
    removeAllOfferProducts,
    removeOfferItem,
} from '../../store/offerStore';
import { OfferState, OfferPersistState } from '../../store/reducers';
import { PopupDialog } from '../../../../components/PopupDialog';

type StateProps = {
    availableColors: ProductColor[];
    availableWeights: number[];
    offerState: OfferState;
    rotationRates: CartRotationRate[];
    persistedValues: OfferPersistState;
};

type DispatchProps = {
    loadOfferOverview: typeof loadOfferOverview;
    fetchCartItem: typeof fetchCartItem;
    clearCartAfterSubmit: typeof clearCartAfterSubmit;
    editCartItem: typeof editCartItem;
    updateCustomerDetails: typeof updateCustomerDetails;
    updateContactDetails: typeof updateContactDetails;
    removeOfferItem: typeof removeOfferItem;
    removeAllOfferProducts: typeof removeAllOfferProducts;
    updateOfferItemDetails: typeof updateOfferItem;
    clearOfferForm: typeof clearOfferForm;
    resetOfferForm: typeof resetOfferForm;
    persistOfferValues: typeof persistOfferValues;
    persistOfferWarnings: typeof persistOfferWarnings;
};

type Props = StateProps & DispatchProps & RouteComponentProps;

type State = {
    selectedItemId: number | null;
    showExpiredCannotOpenModal: boolean;
    showDiscontinuedCannotOpenModal: boolean;
};

const DATE_FORMAT = 'YYYY-MM-DD';

const DISCONTINUED_ARTICLE = 'Ausgelaufener Artikel';

const DISCONTINUED_ARTICLE_TEXT_POPUP =
    'Dieser Artikel kann nicht bearbeitet werden, da er aus dem Sortiment genommen wurde. Eine andere Farbe oder ein anderes Gewicht des Produkts kann aus dem Katalog ausgewählt werden.';

const EXPIRED_ARTICLE = "Nicht verfügbarer Artikel";

const EXPIRED_ARTICLE_TEXT_POPUP = "Dieser Artikel kann nicht bearbeitet werden, da er nicht mehr verfügbar ist. Eine andere Farbe oder ein anderes Gewicht des Produkts kann aus dem Katalog ausgewählt werden.";


class NewOffer extends React.PureComponent<Props, State> {
    state = {
        selectedItemId: null,
        showDiscontinuedCannotOpenModal: false,
        showExpiredCannotOpenModal: false,
    };

    componentDidMount() {
        this.props.loadOfferOverview();
    }

    private getFormInitValues = () => {
        const { persistedValues } = this.props;

        return {
            ...getOfferFormDefaultValues(),
            ...persistedValues.form,
            validUntil: persistedValues.expirationDate
                ? moment(persistedValues.expirationDate).format(DATE_FORMAT)
                : undefined,
            backgroundImageUri: persistedValues.background || undefined,
            areTermsAndConditions: persistedValues.includeTermsAndConditions || true,
            kmuDetails: persistedValues.kmuDetails || undefined,
        };
    };

    private getFormInitWarnings = () => ({
        colorWarnings: this.props.persistedValues.colorWarnings || {},
        weightWarnings: this.props.persistedValues.weightWarnings || {},
    });

    private submitContactDetails = ({
        assistantContact = {},
        employeeContact = {},
        contactComments,
    }: ContactDetailsValues) => {
        this.props.updateContactDetails(
            employeeContact as ContactData,
            assistantContact as ContactData,
            contactComments,
        );
        notificationService.success('Kontaktdaten wurden gespeichert.');
    };

    private submitCustomerDetails = ({
        customerCompany,
        customerContact,
    }: CustomerDetailsValues) => {
        this.props.updateCustomerDetails(customerCompany, customerContact);
        notificationService.success('Kontaktdaten wurden gespeichert.');
    };

    private changeItemColor = async (cartItemId: number, colorKey: string) => {
        try {
            const item = this.props.offerState.data?.items.find((i) => i.cartItemId === cartItemId);

            const response = await cartService.editCartItemColor(cartItemId, colorKey);

            if (item) {
                this.props.updateOfferItemDetails(
                    createNewOfferItemDetailsFromCartResponse(item, response),
                );
            }
        } catch {
            return false;
        }
        return true;
    };

    private changeItemWeight = async (cartItemId: number, weight: number) => {
        try {
            const item = this.props.offerState.data?.items.find((i) => i.cartItemId === cartItemId);

            const response = await cartService.editCartItemWeight(cartItemId, weight);

            if (item) {
                this.props.updateOfferItemDetails(
                    createNewOfferItemDetailsFromCartResponse(item, response),
                );
            }
        } catch {
            return false;
        }
        return true;
    };

    private submitOfferItem = async (
        product: Product,
        variant: ProductVariant,
        formData: OfferFormItem,
        totalPrice: number,
    ) => {
        const { selectedItemId } = this.state;
        try {
            const cartItemData = prepareCartItemData(product, variant, formData, totalPrice);
            this.props.editCartItem(selectedItemId!, cartItemData);

            const offerItemData = prepareVariantItem(product, variant, formData, totalPrice);
            this.props.updateOfferItemDetails({
                cartItemId: selectedItemId,
                offerItemId: null,
                ...offerItemData,
            });
        } catch {
            notificationService.error('Bitte aktualisieren Sie fehlende Dateneingaben');
        }

        this.setState({ selectedItemId: null });
    };

    private removeOfferItem = async (cartItemId: number) => {
        try {
            await this.props.removeOfferItem(cartItemId);
            notificationService.success('Artikel aus dem Warenkorb entfernt');
        } catch {
            notificationService.error();
        }
    };

    private clearOfferProducts = async () => {
        try {
            await this.props.removeAllOfferProducts();
            notificationService.success('Wagen wurde geräumt');
        } catch {
            notificationService.error();
        }
    };

    private clearOfferForm = async () => {
        try {
            await this.props.clearOfferForm();
            await this.props.loadOfferOverview();
            notificationService.success('Angaben wurden gelöscht');
        } catch {
            notificationService.error();
        }
    };

    private createRequestObject = (item: VariantItem, value: JsonObject) => {
        Object.keys(value!).forEach((item: string) => {
            if (value && value[item as keyof typeof value] === undefined) {
                value[item] = null;
            }
        });

        const keepData = pick(['logoBackPrice', 'logoFrontPrice', 'labelPrice'], item);
        return { ...keepData, ...value };
    };

    onItemInlineChange = async (item: VariantItem, value?: JsonObject) => {
        try {
            const totalPrice = calculateTotalPriceByCartItem(item, value!);
            const response = await cartService.editCartItemPricing(item.cartItemId!, {
                ...this.createRequestObject(item, value!),
                totalPrice,
            });

            this.props.updateOfferItemDetails(
                createNewOfferItemDetailsFromCartResponse(item, response),
            );
        } catch {
            notificationService.error('Bitte aktualisieren Sie fehlende Dateneingaben');
        }
    };

    onOfferItemClick = async (id: VariantItem['offerItemId'], item: VariantItem) => {
        if (id) {
            this.setState({
                selectedItemId: id,
                showDiscontinuedCannotOpenModal: item.discontinued,
                showExpiredCannotOpenModal: item.expired,
            });

            this.props.fetchCartItem(id);
        } else {
            notificationService.error();
        }
    };

    onOfferItemCancelEdit = () => {
        this.setState({
            selectedItemId: null,
            showExpiredCannotOpenModal: false,
            showDiscontinuedCannotOpenModal: false,
        });
    };

    afterOfferSubmit = async (result: number) => {
        this.props.resetOfferForm();
        this.props.clearCartAfterSubmit();
        fetchOfferDetails(result)
            .then(({ offerItems, offerBasics, offerDetails }) => {
                createOfferPdf({
                    items: offerItems,
                    basics: offerBasics,
                    details: offerDetails,
                    displayCategory: false,
                });
            })
            .finally(() => {
                this.props.history.push(generatePath(paths.savedOffers));
            });
    };

    onFormSubmit = (formValues: OfferFormValues) => {
        const { items, basics } = this.props.offerState.data!;
        return submitOfferForm(items, basics!, formValues, this.afterOfferSubmit, false);
    };

    private changeItemPricing = async (item: VariantItem, valueChanges: CartItemPricing) => {
        const totalPrice = calculateTotalPriceByCartItem(item, valueChanges);

        const response = await cartService.editCartItemPricing(item.cartItemId!, {
            ...valueChanges,
            totalPrice,
        });

        this.props.updateOfferItemDetails({
            ...item,
            itemsPerWearer: response.itemsPerWearer || null,
        });
    };

    render() {
        const { availableColors, availableWeights, offerState, rotationRates, ...rest } =
            this.props;

        const offerItems = offerState.data?.items;
        const offerBasics = offerState.data?.basics;

        const errorDetails =
            offerState.errorCode === StatusCodes.INTERNAL_SERVER_ERROR
                ? ({
                      status: StatusCodes.INTERNAL_SERVER_ERROR,
                      subTitle: <React.Fragment />,
                      extra: <ClearFormButton onClick={this.clearOfferForm} />,
                  } as ErrorProps)
                : undefined;

        return (
            <>
                {
                    this.state.showExpiredCannotOpenModal && (
                        <PopupDialog
                            title={EXPIRED_ARTICLE}
                            text={EXPIRED_ARTICLE_TEXT_POPUP}
                            closeDialog={() => {
                                this.setState({
                                    selectedItemId: null,
                                    showDiscontinuedCannotOpenModal: false,
                                    showExpiredCannotOpenModal: false,
                                })
                            }
                            }
                        ></PopupDialog>
                    )
                }

                {
                    this.state.showDiscontinuedCannotOpenModal &&
                    !this.state.showExpiredCannotOpenModal && (
                        <PopupDialog
                            title={DISCONTINUED_ARTICLE}
                            text={DISCONTINUED_ARTICLE_TEXT_POPUP}
                            closeDialog={() =>
                                this.setState({
                                    selectedItemId: null,
                                    showDiscontinuedCannotOpenModal: false,
                                })
                            }
                        ></PopupDialog>
                    )
                }

                <AsyncResourceHandler
                    data={{ offerBasics, offerItems }}
                    error={offerState.error}
                    loading={offerState.loading}
                    errorDetails={errorDetails}
                >
                    {({ offerBasics, offerItems }) => (
                        <>
                            <OfferLayout
                                {...rest}
                                rotationRates={rotationRates}
                                initFormValues={this.getFormInitValues()}
                                initFormWarnings={this.getFormInitWarnings()}
                                offerItems={offerItems || []}
                                customerCompany={offerBasics?.customerCompany}
                                customerContact={offerBasics?.customerContact}
                                employeeContact={offerBasics?.employeeContact}
                                offerServices={offerBasics?.wardrobeOptions || []}
                                includeTermsAndConditions={
                                    offerBasics?.includeTermsAndConditions || true
                                }
                                availableColors={availableColors}
                                availableWeights={availableWeights}
                                contactComments={offerBasics?.contactComments}
                                assistantContact={offerBasics?.assistantContact}
                                onContactDetailsSubmit={this.submitContactDetails}
                                onCustomerDetailsSubmit={this.submitCustomerDetails}
                                changeItemColor={this.changeItemColor}
                                changeItemWeight={this.changeItemWeight}
                                submitOfferItem={this.submitOfferItem}
                                removeOfferItem={this.removeOfferItem}
                                onOfferProductsClear={this.clearOfferProducts}
                                onOfferItemClick={this.onOfferItemClick}
                                onOfferSubmit={this.onFormSubmit}
                                onItemInlineChange={this.onItemInlineChange}
                                onOfferItemCancelEdit={this.onOfferItemCancelEdit}
                                selectedItemId={this.state.selectedItemId}
                                selectedItemIsDiscontinued={this.state.showDiscontinuedCannotOpenModal}
                                selectedItemIsExpired={this.state.showExpiredCannotOpenModal}
                                changeItemPricing={this.changeItemPricing}
                                persistOfferValues={this.props.persistOfferValues}
                                persistOfferWarnings={this.props.persistOfferWarnings}
                                shouldEdit={false}
                            />
                        </>
                    )}
                </AsyncResourceHandler>
            </>
        );
    }
}

const mapStateToProps: MapState<StateProps> = (state) => ({
    availableColors: getOverviewAvailableColors(state),
    availableWeights: getOverviewAvailableWeights(state),
    offerState: getOfferState(state),
    rotationRates: getOverviewRotationRates(state),
    persistedValues: getOfferPersistState(state),
});

const mapDispatchToProps: MapDispatch<DispatchProps> = (dispatch) => ({
    fetchCartItem: (cartItemId) => dispatch(fetchCartItem(cartItemId)),
    loadOfferOverview: () => dispatch(loadOfferOverview()),
    clearCartAfterSubmit: () => dispatch(clearCartAfterSubmit()),
    editCartItem: (cartItemId, cartItemData, silent?) =>
        dispatch(editCartItem(cartItemId, cartItemData, silent)),
    removeOfferItem: (cartItemId) => dispatch(removeOfferItem(cartItemId)),
    updateCustomerDetails: (customerCompany, customerContact) =>
        dispatch(updateCustomerDetails(customerCompany, customerContact)),
    updateContactDetails: (employeeContact, assistantContact, contactComments) =>
        dispatch(updateContactDetails(employeeContact, assistantContact, contactComments)),
    removeAllOfferProducts: () => dispatch(removeAllOfferProducts()),
    updateOfferItemDetails: (offerItem) => dispatch(updateOfferItem(offerItem)),
    clearOfferForm: () => dispatch(clearOfferForm()),
    resetOfferForm: () => dispatch(resetOfferForm()),
    persistOfferValues: (values) => dispatch(persistOfferValues(values)),
    persistOfferWarnings: (warnings) => dispatch(persistOfferWarnings(warnings)),
});

export const NewOfferContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(NewOffer));
